import { useMutation, useQuery } from 'react-query'

import { toCamelCase, toSnakeCase } from '@cutover/api'
import { CamelToSnakeCaseNested, Nullish } from '@cutover/utility-types'
import { apiClient } from 'main/services/api'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import { QueryKeys } from 'main/services/queries/query-keys'

type RunbookTypeCreateSnakeCase = CamelToSnakeCaseNested<RunbookTypeCreate>

/**
 * @deprecated Please use the RunbookTypeCreateSnakeCase type and/or refactor to
 * use the snake case response from the API instead.
 */
interface RunbookTypeCreate {
  name: string | null | undefined
  description: string | null | undefined
  visibility: 'global' | 'account'
  accountId: number | null | undefined
  dynamic: boolean
  incident: boolean
  enableRto: boolean
  restrictCreateToTemplates: boolean
  iconName: string | null | undefined
  iconColor: string | null | undefined
  approvalFlowId?: number | null | undefined
}

type RunbookTypeCreateApiSnakeCase = CamelToSnakeCaseNested<RunbookTypeCreateApi>

/**
 * @deprecated Please use the RunbookTypeCreateApiSnakeCase type and/or refactor to
 * use the snake case response from the API instead.
 */
// TODO: add icon id and color to API, return 422 for duplicate
interface RunbookTypeCreateApi {
  name: string | null | undefined
  description: string | null | undefined
  visibility: 'global' | 'select_accounts'
  accountId: number | null | undefined
  dynamic: boolean
  incident: boolean
  enableRto: boolean
  restrictCreateToTemplates: boolean
  global?: boolean
  approvalFlowId?: number | null | undefined
}

export function useRunbookTypeCreate() {
  return useMutation<RunbookTypeCreate, ApiError, RunbookTypeCreate>(
    'runbook_type',
    async (runbookType: RunbookTypeCreate) => {
      const isGlobal = runbookType.visibility === 'global'

      const runbookTypeApi: RunbookTypeCreateApi = {
        ...runbookType,
        visibility: isGlobal ? 'global' : 'select_accounts',
        ...(isGlobal ? { global: true } : {})
      }

      // TODO: update post response type
      // TODO: refactor. Converting case is not a pattern that should be copied.
      // Favour the snake case response that is returned from the API.
      const { data } = toCamelCase(
        await apiClient.post<{ runbook_type: RunbookTypeCreateApiSnakeCase }, RunbookTypeCreateSnakeCase>({
          url: 'runbook_types',
          data: { runbook_type: toSnakeCase(runbookTypeApi) }
        })
      )

      // TODO: data type should be truthy on success
      return data as RunbookTypeCreate
    }
  )
}

export function useRunbookTypeQuery(runbookTypeId: number | null) {
  return useQuery<any, Error, any>([QueryKeys.RunbookTypes, runbookTypeId], async () => {
    const response = await apiClient.get<any>({
      url: `runbook_types/${runbookTypeId}`
    })

    return response.data as any
  })
}

// TODO: review other JSON reponse
export type RunbookType = {
  id: number
  name: string
  description: string
  visibility: 'global' | 'account'
  accountId: Nullish<number>
  dynamic: boolean
  incident: boolean
  disabled: boolean
  iconName: string
  iconColor: string
  ai_create_enabled?: boolean
  // accountName: string
  // archived: boolean
  // default: boolean
}
